<template>
    <div class="hc31Box">
        <div
            class="hbBox1"
            v-if="getAdvData(21).children"
            :style="`background: url(${getAdvData(21).children[0].image}) no-repeat center/cover;`"
        >
            <div class="h1Box">
                <p class="hb1 wow animate__animated animate__slideInUp">威有科技</p>
                <p class="hb2 wow animate__animated animate__slideInUp">商城网站建设</p>
                <div class="hb3 wow animate__animated animate__slideInUp">
                    <span>PC/APP/小程序/H5</span>多终端数据同步打通，<br />
                    实现多场景营销，线上线下完美结合，<br />
                    助力企业提升品牌价值。
                </div>
                <div class="hb4 wow animate__animated animate__slideInUp">
                    <a :href="getAdvData(21).children[0].url" target="_blank" rel="noopener noreferrer"> 详情咨询 </a>
                    <div class="adbImg">
                        <img class="aiImg" :src="getWebData().public_phone" alt="" />
                    </div>
                </div>
                <div class="hb5Img">
                    <template v-for="(el, i) in getAdvData(21).children[0].children">
                        <img
                            :key="el.id"
                            :src="el.image"
                            :class="`hb5${i + 1}`"
                            class="wow animate__animated animate__slideInUp"
                        />
                    </template>
                </div>
            </div>
        </div>
        <div class="hbBox2">
            <div class="hb2Top wow animate__animated animate__slideInUp">已有10000+品牌企业选择威有科技</div>
            <Dot :num="16" style="margin-top: 10px"></Dot>
            <Dot :num="16" style="margin-top: 10px"></Dot>
            <div class="hb2List">
                <ul>
                    <li
                        class="hb2Li wow animate__animated animate__slideInUp"
                        v-for="item in electrisityShop"
                        :key="item.id"
                        @click="goDetail(item)"
                    >
                        <img :src="item.logo" />
                    </li>
                </ul>
            </div>
        </div>
        <div class="hbBox3">
            <img src="https://iv.vu818.com/img/4.png" alt="" />
            <img src="https://iv.vu818.com/img/4.png" alt="" />
            <div class="hb3Top wow animate__animated animate__slideInUp">产品特色</div>
            <Dot :num="16" style="margin-top: 10px"></Dot>
            <Dot :num="16" style="margin-top: 10px"></Dot>
            <div class="hb3List">
                <ul>
                    <li class="hb3Li wow animate__animated animate__slideInUp" v-for="item in iconList" :key="item">
                        <div class="h3lBox">
                            <div class="h3l1">
                                <img :src="item" alt="" />
                            </div>
                            <p class="h3l2">全网营销</p>
                            <p class="h3l3">
                                PC+APP +微信h5+小程序四合一 <br />
                                统一后台管理，实现全终端数据同步<br />
                                更新<br />
                                全网同步销售，快速引爆销量
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="hb3Btn">
                详情咨询
                <div class="adbImg">
                    <img class="aiImg" :src="getWebData().public_phone" alt="" />
                </div>
            </div>
        </div>
        <div class="hbBox4">
            <div class="hb4Top wow animate__animated animate__slideInUp">电商解决方案</div>
            <Dot :num="16" color="white" style="margin-top: 10px"></Dot>
            <Dot :num="16" color="white" style="margin-top: 10px"></Dot>
            <div class="hb4Box">
                <div
                    class="h4Left animate__animated"
                    v-for="(item, index) in listContent"
                    :key="index"
                    v-show="showH4L === index"
                    :class="{ animate__fadeInUp: theAni === index }"
                >
                    <p class="hl1">{{ item.title }}</p>
                    <p class="hl2">{{ item.p }}</p>
                    <div class="hl3">
                        <ul>
                            <li class="hl3Li" v-for="(el, ind) in item.children" :key="ind">
                                <img src="http://iv.vu818.com/img/26.png" alt="" class="h3Icon" />
                                {{ el }}
                            </li>
                        </ul>
                    </div>
                    <div class="hl4">
                        详情咨询
                        <div class="adbImg">
                            <img class="aiImg" :src="getWebData().public_phone" alt="" />
                        </div>
                    </div>
                </div>
                <div class="h4Right">
                    <ul>
                        <li
                            class="h4rLi"
                            v-for="(item, index) in list"
                            :key="index"
                            @click="getH4(index)"
                            :class="{ h4Color: h4C === index }"
                        >
                            <img src="http://iv.vu818.com/img/33.png" alt="" class="h4Act" v-show="h4A === index" />
                            <img src="http://iv.vu818.com/img/32.png" alt="" class="h4lIcon" /> {{ item }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox5">
            <div class="hb5Top wow animate__animated animate__slideInUp">品牌保证</div>
            <Dot :num="16" style="margin-top: 10px"></Dot>
            <Dot :num="16" style="margin-top: 10px"></Dot>
            <div class="hb5List">
                <ul>
                    <li class="h5lLi" v-for="item in iconList2" :key="item">
                        <div class="h5lBox">
                            <div class="h5b1 wow animate__animated animate__slideInUp">
                                <img :src="item" alt="" />
                            </div>
                            <div class="h5b2">
                                <p class="h21 wow animate__animated animate__slideInUp">6年专注网站建设</p>
                                <p class="h22 wow animate__animated animate__slideInUp">
                                    6年行业经验，我们更懂网站，从根本上了解客户需求
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="hb5More">
                详情咨询
                <div class="adbImg">
                    <img class="aiImg" :src="getWebData().public_phone" alt="" />
                </div>
            </div>
        </div>
        <div class="hbBox6">
            <div class="hb6Top wow animate__animated animate__slideInUp">案例展示</div>
            <Dot :num="16" style="margin-top: 10px"></Dot>
            <Dot :num="16" style="margin-top: 10px"></Dot>
            <div class="hb6List">
                <ul>
                    <li
                        class="h6lLi wow animate__animated animate__slideInUp"
                        v-for="item in electrisityShop"
                        :key="item.id"
                        @click.prevent="goDetail(item)"
                    >
                        <img v-lazy="item.little_image" />
                        <div class="h6Mask" v-if="item.qrcode || item.logo">
                            <img v-if="item.qrcode" v-lazy="item.qrcode" class="h6mCode" />
                            <img v-else v-lazy="item.logo" class="h6mCode" />
                            <!-- <p class="h6m1">铭普光磁</p> -->
                        </div>
                    </li>
                </ul>
            </div>
            <div class="hb6More">
                详情咨询
                <div class="adbImg">
                    <img class="aiImg" :src="getWebData().public_phone" alt="" />
                </div>
            </div>
        </div>
        <div class="hbBox7">
            <div class="hb7Box">
                <ul>
                    <li class="hb7Li wow animate__animated animate__slideInUp" v-for="item in iconList3" :key="item">
                        <div class="h7lBox">
                            <div class="h7Left">
                                <p class="h7l1">量身定制</p>
                                <p class="h7l1">解决方案</p>
                                <p class="h7l2">1对1专家咨询,</p>
                                <p class="h7l2">深度剖析客户需求</p>
                            </div>
                            <div class="h7Right">
                                <img :src="item" alt="" />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Dot from "../dot.vue";
export default {
    name: "HsContent31",
    components: { Dot },
    inject: ["getWebData", "getAdvData"],
    data() {
        return {
            h4A: 1,
            h4C: 1,
            showH4L: 1,
            theAni: 1,
            iconList: [
                "./img/icon-5.png",
                "./img/icon-12.png",
                "./img/icon-13.png",
                "./img/icon-2.png",
                "./img/icon-14.png",
                "./img/icon-15.png",
            ],
            iconList2: [
                "./img/icon-7.png",
                "./img/icon-8.png",
                "./img/icon-2.png",
                "./img/icon-9.png",
                "./img/icon-10.png",
                "./img/icon-11.png",
            ],
            iconList3: ["./img/icon-16.png", "./img/icon-17.png", "./img/icon-18.png"],
            list: [
                "B2C在线商城系统1",
                "B2C在线商城系统2",
                "B2C在线商城系统3",
                "B2C在线商城系统4",
                "B2C在线商城系统5",
                "B2C在线商城系统6",
            ],
            listContent: [
                {
                    title: "B2C在线商城系统1",
                    p: "搭建品牌/企业的官方自有商场系统",
                    children: ["jjj", "jjj", "jjj"],
                },
                {
                    title: "B2C在线商城系统2",
                    p: "搭建品牌/企业的官方自有商场系统",
                    children: ["jjj", "jjj", "jjj"],
                },
                {
                    title: "B2C在线商城系统3",
                    p: "搭建品牌/企业的官方自有商场系统",
                    children: ["jjj", "jjj", "jjj"],
                },
                {
                    title: "B2C在线商城系统4",
                    p: "搭建品牌/企业的官方自有商场系统",
                    children: ["jjj", "jjj", "jjj"],
                },
                {
                    title: "B2C在线商城系统5",
                    p: "搭建品牌/企业的官方自有商场系统",
                    children: ["jjj", "jjj", "jjj"],
                },
                {
                    title: "B2C在线商城系统6",
                    p: "搭建品牌/企业的官方自有商场系统",
                    children: ["jjj", "jjj", "jjj"],
                },
            ],
            electrisityShop: [],
        };
    },
    async created() {
        await this.getData();
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
    methods: {
        getH4(i) {
            this.h4A = i;
            this.h4C = i;
            this.showH4L = i;
            this.theAni = i;
        },
        async getData() {
            const { data } = await axios.get("api/project/electrisityShop");
            this.electrisityShop = data;
        },
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            // 点击后的回调
            this.$emit("callBack");
        },
    },
};
</script>

<style lang="less" scoped>
.hc31Box {
    width: 100%;
    margin-top: 78px;
    text-align: left;
    .hbBox1 {
        width: 100%;
        height: 618px;
        overflow: hidden;

        text-align: left;
        .h1Box {
            max-width: 900px;
            height: 100%;
            margin: 0 auto;
            margin-top: 100px;
            position: relative;
            .hb1 {
                width: 100%;
                height: 58px;
                line-height: 58px;
                color: white;
                font-size: 48px;
            }
            .hb2 {
                width: 100%;
                height: 58px;
                line-height: 58px;
                font-size: 48px;
                color: #ffcc00;
            }
            .hb3 {
                width: 100%;
                height: 90px;
                line-height: 30px;
                font-size: 19px;
                margin-top: 50px;
                color: white;
                span {
                    color: #ffcc00;
                }
            }
            .hb4 {
                width: 127px;
                height: 37px;
                border-radius: 25px;
                background-color: #ffcc00;
                line-height: 37px;
                text-align: center;
                font-size: 12px;
                margin-top: 50px;
                cursor: pointer;
                transition: 0.5s;
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                .adbImg {
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    top: -110px;
                    left: 10px;
                    opacity: 0;
                    translate: 1s;
                    .aiImg {
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                    }
                }
            }
            .hb4:hover {
                transform: translateY(-5px);
                .adbImg {
                    opacity: 1;
                    translate: 1s;
                }
            }
            .hb5Img {
                .hb51 {
                    position: absolute;
                    right: 120px;
                    top: 50px;
                    width: 381px;
                    height: 450px;
                }
                .hb52 {
                    position: absolute;
                    right: 480px;
                    bottom: 180px;
                    width: 94px;
                    height: 144px;
                }
                .hb53 {
                    position: absolute;
                    right: 440px;
                    top: 50px;
                    width: 53px;
                    height: 140px;
                }
                .hb54 {
                    position: absolute;
                    right: 370px;
                    top: 20px;
                    width: 39px;
                    height: 57px;
                }
                .hb55 {
                    position: absolute;
                    right: 230px;
                    top: -20px;
                    width: 46px;
                    height: 69px;
                }
                .hb56 {
                    position: absolute;
                    right: 110px;
                    top: 50px;
                    width: 40px;
                    height: 58px;
                }
                .hb57 {
                    position: absolute;
                    right: 50px;
                    top: 180px;
                    width: 48px;
                    height: 69px;
                }
                .hb58 {
                    position: absolute;
                    right: 0px;
                    top: 350px;
                    width: 138px;
                    height: 92px;
                }
                .hb59 {
                    position: absolute;
                    right: 320px;
                    top: 420px;
                    width: 143px;
                    height: 95px;
                }
            }
        }
    }
    .hbBox2 {
        width: 100%;
        height: 285px;
        overflow: hidden;
        .hb2Top {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 37px;
            margin-top: 50px;
        }
        .hb2List {
            max-width: 900px;
            height: 51px;
            margin: 0 auto;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .hb2Li {
                    min-width: 76px;
                    height: 51px;
                    transition: 0.5s;
                    border-radius: 10px;
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        border-radius: 10px;
                    }
                }
                .hb2Li:hover {
                    transform: translateY(-10px);
                    box-shadow: 1px 1px 10px 1px gainsboro;
                }
            }
        }
    }
    .hbBox3 {
        width: 100%;
        height: 1154px;
        overflow: hidden;
        background: url("http://iv.vu818.com/img/3.jpg") no-repeat center/cover;
        position: relative;
        & > img {
            position: absolute;
            animation: linear an 4s infinite;
            z-index: 3;
            width: 200px;
            @keyframes an {
                0% {
                    transform: translate(0px, 0px);
                }
                25% {
                    transform: translate(-10px, -20px);
                }
                50% {
                    transform: translate(0px, 0px);
                }
                75% {
                    transform: translate(10px, 20px);
                }
                100% {
                    transform: translate(0px, 0px);
                }
            }
        }
        & > img:nth-of-type(1) {
            top: 250px;
            right: 180px;
        }
        & > img:nth-of-type(2) {
            top: 680px;
            left: 180px;
        }
        .hb3Top {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 37px;
            margin-top: 80px;
        }
        .hb3List {
            max-width: 900px;
            height: 831px;
            margin: 0 auto;
            margin-top: 80px;
            position: relative;
            z-index: 8;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                .hb3Li {
                    width: 279px;
                    height: 394px;
                    transition: 0.5s;
                    border-radius: 10px;
                    background-color: white;
                    box-shadow: 1px 1px 10px 1px gainsboro;

                    .h3lBox {
                        width: 85%;
                        height: 80%;
                        margin: 0 auto;
                        margin-top: 10%;
                        overflow: hidden;
                        .h3l1 {
                            width: 82px;
                            height: 82px;
                            background-color: #fff;
                            margin-top: 10px;
                            position: relative;
                            overflow: hidden;
                            img {
                                width: 100%;
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                animation: dh1 0.1s steps(9) forwards;
                                @keyframes dh1 {
                                    0% {
                                        top: -737px;
                                    }
                                    100% {
                                        top: 0px;
                                    }
                                }
                            }
                        }
                        .h3l2 {
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                            font-size: 21px;
                            margin-top: 10px;
                            position: relative;
                        }
                        .h3l2::after {
                            content: "";
                            position: absolute;
                            right: 0;
                            top: 10px;
                            width: 8px;
                            height: 8px;
                            background-color: #0053db;
                            opacity: 0.5;
                        }
                        .h3l3 {
                            width: 100%;
                            min-height: 96px;
                            line-height: 20px;
                            font-size: 13px;
                            margin-top: 20px;
                        }
                    }
                }
                .hb3Li:hover {
                    transform: translateY(-20px);
                    box-shadow: 1px 1px 10px 1px gray;
                    .h3l1 {
                        img {
                            animation: dh 0.1s steps(9) forwards;
                            @keyframes dh {
                                0% {
                                    top: 0px;
                                }
                                100% {
                                    top: -737px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .hb3Btn {
            width: 127px;
            height: 37px;
            line-height: 37px;
            text-align: center;
            font-size: 12px;
            color: white;
            border-radius: 25px;
            background-color: #7781f1;
            margin: 0 auto;
            margin-top: 30px;
            transition: 0.5s;
            position: relative;
            z-index: 9;
            .adbImg {
                width: 100px;
                height: 100px;
                position: absolute;
                bottom: 50px;
                left: 15px;
                opacity: 0;
                translate: 1s;
                .aiImg {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                }
            }
        }
        .hb3Btn:hover {
            transform: translateY(-5px);
            .adbImg {
                opacity: 1;
                translate: 1s;
            }
        }
    }
    .hbBox4 {
        width: 100%;
        height: 700px;
        background: url("http://iv.vu818.com/img/25.jpg");
        overflow: hidden;
        .hb4Top {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 37px;
            color: white;
            margin-top: 50px;
        }
        .hb4Box {
            width: 70%;
            height: 500px;
            margin: 0 auto;
            margin-top: 70px;
            .h4Left {
                width: 600px;
                min-height: 420px;
                float: left;
                color: white;
                .hl1 {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 30px;
                }
                .hl2 {
                    width: 100%;
                    height: 18px;
                    font-size: 18px;
                    line-height: 18px;
                    margin-top: 10px;
                }
                .hl3 {
                    width: 100%;
                    min-height: 162px;
                    margin-top: 20px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-around;
                        .hl3Li {
                            width: 100%;
                            min-height: 21px;
                            line-height: 21px;
                            font-size: 15px;
                            .h3Icon {
                                height: 21px;
                                float: left;
                                margin-right: 10px;
                            }
                        }
                        .hl3Li:nth-child(n + 2) {
                            margin-top: 20px;
                        }
                    }
                }
                .hl4 {
                    width: 127px;
                    height: 37px;
                    background-color: #7781f1;
                    cursor: pointer;
                    line-height: 37px;
                    font-size: 12px;
                    text-align: center;
                    border-radius: 25px;
                    margin-top: 20px;
                    position: relative;
                    transform: translateY(0px);
                    transition: 0.5s linear;
                    .adbImg {
                        width: 100px;
                        height: 100px;
                        position: absolute;
                        bottom: 60px;
                        right: 15%;
                        opacity: 0;
                        transition: 0.5s;
                        .aiImg {
                            width: 100%;
                            height: 100%;
                            border-radius: 20px;
                        }
                    }
                }
                .hl4:hover {
                    transform: translateY(-10px);
                    .adbImg {
                        opacity: 1;
                    }
                }
                .hl5 {
                    width: 100%;
                    height: 60px;
                    margin-top: 30px;
                    .h5Ul {
                        width: 100%;
                        height: 60px;
                        display: flex;
                        // justify-content: space-around;
                        .hl5Li {
                            width: 100px;
                            height: 60px;
                            border-radius: 10px;
                            overflow: hidden;
                            cursor: pointer;
                            margin-right: 25px;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }
            .h4Right {
                width: 155px;
                height: 455px;
                float: left;
                margin-left: 120px;
                color: white;
                border-left: 1px solid rgba(255, 255, 255, 0.1);
                position: relative;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    // justify-content: space-around;
                    // flex-wrap: wrap;
                    flex-direction: column;
                    align-items: center;
                    .h4rLi {
                        width: 100%;
                        height: 18px;
                        font-size: 13px;
                        line-height: 18px;
                        text-align: right;
                        position: relative;
                        color: #ffffff99;
                        cursor: pointer;
                        margin-bottom: 20px;
                        .h4Act {
                            width: 42px;
                            height: 42px;
                            position: absolute;
                            left: -22px;
                            top: -12px;
                        }
                        .h4lIcon {
                            position: absolute;
                            left: -10px;
                            cursor: pointer;
                        }
                    }
                    .h4rLi:hover {
                        color: white;
                    }
                    .h4Color {
                        color: white;
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .hbBox5 {
        max-width: 1430px;
        height: 900px;
        overflow: hidden;
        margin: 0 auto;
        .hb5Top {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 37px;
            margin-top: 50px;
        }
        .hb5List {
            width: 100%;
            height: 541px;
            margin-top: 70px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                .h5lLi {
                    width: 50%;
                    height: 181px;
                    border: 1px solid #e1e6f0;
                    box-sizing: border-box;
                    border-radius: 10px;
                    .h5lBox {
                        width: 450px;
                        height: 129px;
                        .h5b1 {
                            width: 82px;
                            height: 82px;
                            margin-top: 20px;
                            float: left;
                            position: relative;
                            overflow: hidden;
                            img {
                                width: 100%;
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                animation: dh5 0.1s steps(9) forwards;
                                @keyframes dh5 {
                                    0% {
                                        top: -737px;
                                    }
                                    100% {
                                        top: 0px;
                                    }
                                }
                            }
                        }
                        .h5b2 {
                            width: 322px;
                            height: 192px;
                            float: left;
                            margin-top: 30px;
                            .h21 {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                font-size: 22px;
                            }
                            .h22 {
                                width: 100%;
                                height: 21px;
                                line-height: 21px;
                                font-size: 12px;
                                margin-top: 10px;
                            }
                        }
                    }
                }
                .h5lLi:nth-child(odd) {
                    .h5lBox {
                        margin-top: 20px;
                        margin-left: 200px;
                    }
                }
                .h5lLi:nth-child(even) {
                    .h5lBox {
                        margin-top: 20px;
                        margin-left: 60px;
                    }
                }
                .h5lLi:hover {
                    background: url("http://iv.vu818.com/img/35.png") no-repeat center;
                    .h5lBox {
                        .h5b1 {
                            img {
                                animation: dh4 0.1s steps(9) forwards;
                                @keyframes dh4 {
                                    0% {
                                        top: 0px;
                                    }
                                    100% {
                                        top: -737px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .hb5More {
            width: 127px;
            height: 40px;
            margin: 0 auto;
            margin-top: 70px;
            font-size: 15px;
            line-height: 40px;
            text-align: center;
            border-radius: 25px;
            color: white;
            background-color: #7781f1;
            transition: 0.5s;
            .adbImg {
                width: 100px;
                height: 100px;
                position: absolute;
                bottom: 60px;
                right: 15%;
                opacity: 0;
                translate: 1s;
                .aiImg {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                }
            }
        }
        .hb5More:hover {
            transform: translateY(-10px);
            .adbImg {
                opacity: 1;
                translate: 1s;
            }
        }
    }
    .hbBox6 {
        width: 100%;
        // height: 800px;
        background: url("http://iv.vu818.com/img/36.jpg");
        overflow: hidden;
        margin-bottom: 10px;
        .hb6Top {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 37px;
            margin-top: 50px;
        }
        .hb6List {
            max-width: 996px;
            // height: 470px;
            margin: 0 auto;
            margin-top: 70px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                .h6lLi {
                    width: 274px;
                    height: 216px;
                    border-radius: 10px;
                    overflow: hidden;
                    position: relative;
                    cursor: pointer;
                    margin-bottom: 10px;
                    & > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: 0.5s;
                    }
                    .h6Mask {
                        width: 274px;
                        height: 216px;
                        border-radius: 10px;
                        background-color: rgba(0, 0, 0, 0.5);
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: 0.5s;
                        opacity: 0;
                        .h6mCode {
                            width: 100px;
                            height: 100px;
                            object-fit: contain;
                            border-radius: 5px;
                            display: block;
                            margin: 0 auto;
                            margin-top: 60px;
                        }
                        .h6m1 {
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            color: white;
                            font-size: 25px;
                            margin-top: 15px;
                        }
                    }
                }
                .h6lLi:hover {
                    img {
                        transform: scale(1.1);
                    }
                    .h6Mask {
                        opacity: 1;
                    }
                }
            }
        }
        .hb6More {
            width: 127px;
            height: 37px;
            margin: 0 auto;
            margin-top: 50px;
            font-size: 12px;
            line-height: 37px;
            text-align: center;
            border-radius: 25px;
            color: white;
            background-color: #7781f1;
            transition: 0.5s;
            cursor: default;
            .adbImg {
                width: 100px;
                height: 100px;
                position: absolute;
                bottom: 50px;
                left: 15px;
                opacity: 0;
                translate: 1s;
                .aiImg {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                }
            }
        }
        .hb6More:hover {
            transform: translateY(-10px);
            .adbImg {
                opacity: 1;
                translate: 1s;
            }
        }
    }
    .hbBox7 {
        width: 100%;
        height: 320px;
        background: url("http://iv.vu818.com/img/48.jpg") no-repeat center/cover;
        overflow: hidden;
        .hb7Box {
            max-width: 900px;
            height: 200px;
            margin: 0 auto;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .hb7Li {
                    width: 282px;
                    height: 200px;
                    background-color: white;
                    border-radius: 10px;
                    .h7lBox {
                        width: 80%;
                        height: 80%;
                        margin: 0 auto;
                        margin-top: 7%;
                        position: relative;
                        .h7Left {
                            width: 100%;
                            height: 100%;
                            .h7l1 {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                font-size: 22px;
                            }

                            .h7l2 {
                                width: 100%;
                                height: 21px;
                                line-height: 21px;
                                font-size: 12px;
                                color: gray;
                                margin-top: 20px;
                                position: relative;
                            }
                            .h7l2:nth-child(4) {
                                margin: 0;
                            }
                            .h7l2:nth-child(4)::after {
                                content: "";
                                position: absolute;
                                right: 0;
                                top: 10px;
                                width: 8px;
                                height: 8px;
                                background-color: #0053db;
                                opacity: 0.5;
                            }
                        }

                        .h7Right {
                            width: 82px;
                            height: 82px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            overflow: hidden;
                            img {
                                width: 100%;
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                animation: dh3 0.1s steps(9) forwards;
                                @keyframes dh3 {
                                    0% {
                                        top: -737px;
                                    }
                                    100% {
                                        top: 0px;
                                    }
                                }
                            }
                        }
                        &:hover {
                            .h7Right {
                                img {
                                    animation: dh2 0.1s steps(9) forwards;
                                    @keyframes dh2 {
                                        0% {
                                            top: 0px;
                                        }
                                        100% {
                                            top: -737px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
